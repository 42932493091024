import { useEffect, Dispatch, SetStateAction } from "react"
import * as THREE from 'three'
import { TSteppedModelData, TTeethModelData } from '../types'
import getSmilewrapperModelsData from './getSmilewrapperModelsData'
import getObjModelsData          from './getObjModelsData'
import getMultiZipObjModelsData from "./getMultiZipObjModelData"
import { Event, SCXML, SingleOrArray } from "xstate"
import { TMachineStateEvent } from "../Fsm/viewerStateMachine"
import getAnimationFlagFromUrl from "../Utils/getAnimationFlagFromUrl"

type TModelLoaderProps = {
    url: string[]
    setTeethModelData               : Dispatch< SetStateAction< TTeethModelData        >>
    setGingivaModelData             : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelGeometry           : Dispatch< SetStateAction< TSteppedModelData      >>
    setTeethModelStepTransformation : Dispatch< SetStateAction< TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch< SetStateAction< string | undefined     >>
    onGlobalError                   : (errorString:string) => void
    sendViewerEvent                 : (eventId: SCXML.Event<TMachineStateEvent> | SingleOrArray<Event<TMachineStateEvent>>) => void
}

export type TTeethStepsPosition = {
    id: string
    position: THREE.Vector3
    rotationMatrix: THREE.Matrix3
}


const ModelLoader = (props: TModelLoaderProps) => {
    const {
        url,
        setTeethModelData,
        setGingivaModelData,
        setTeethModelStepTransformation,
        setTeethModelGeometry,
        setSmilewrapperInfo,
        onGlobalError,
        sendViewerEvent,
    } = props

    useEffect(()=>{

        if( url.length === 1 ){
            // ZIP with smilewrapper format inside

            if(url[0].indexOf('.zip')>-1){
                getSmilewrapperModelsData({
                    url:url[0],
                    setTeethModelData,
                    setGingivaModelData,
                    setTeethModelStepTransformation,
                    setSmilewrapperInfo,
                    onGlobalError,
                    onFinish:()=>{
                        if(getAnimationFlagFromUrl() === false){
                            sendViewerEvent({type:'event_loading_finished_no_animation'})
                        }else{
                            setTimeout(()=>{
                                sendViewerEvent('event_loading_finish')
                            },500)
                        }
                    }
                })
            }

            // CASE WITH JSON AND OBJ-FILES BESIDE
            if(url[0].indexOf('.json')>-1){

                getObjModelsData({
                    url:url[0],
                    setGingivaModelData,
                    setTeethModelGeometry,
                    onFinish:()=>{
                        setTimeout(()=>{
                            sendViewerEvent('event_loading_finish')
                        },500)
                    }
                })
            }
            
        } else if(url.length > 1){
            getMultiZipObjModelsData({
                urlArray:url,
                setGingivaModelData,
                setTeethModelGeometry,
                onGlobalError,
                onFinish: ()=>{
                    if(getAnimationFlagFromUrl() === false){
                        sendViewerEvent({type:'event_loading_finished_no_animation'})
                    }else{
                        setTimeout(()=>{
                            sendViewerEvent('event_loading_finish')
                        },500)
                    }
                }
            })
        }
    // eslint-disable-next-line
    },[url])

    return(
        <>
        </>
    )
}

export default ModelLoader
