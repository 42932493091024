import * as THREE from 'three'
import { TModelData, TViewValue } from "../../types"
import { getBaseRotation } from '../ModelViewController'
import { getViewVisibility } from './Teeths'

type TGingivaProps = {
    modelName   : string
    modelData   : TModelData[],
    currentView : TViewValue
}

export const gingivaMaterial = new THREE.MeshPhongMaterial({
    color: 0xff8484,
    shininess: 10,
    specular: 0x111111,
    flatShading: true,
})

const Gingiva = ( props:TGingivaProps ) => {
    
    const { 
        modelData, 
        modelName,
        currentView 
    } = props
    
    return(
        <group
            name     = { modelName                                }
            rotation = { getBaseRotation(currentView)             }
            visible  = { getViewVisibility(currentView,modelName) }
        >
            {
                modelData.map((modelItem, modelIndex)=>{

                    const { name, data } = modelItem
                    let   newModelName        = name
                    const splittedName = name.split('_')
                    if(splittedName.length === 3){


                        const splittedFileName = splittedName[2].split('.')
                        
                        newModelName = `teeth-${splittedFileName[0]}`
                    }

                    return(
                        <mesh
                            name={ newModelName }
                            key={`${modelName}-gingiva-item-${modelIndex}`}
                            geometry={ data }
                            material={ gingivaMaterial }
                        />
                    )
                })
            }
        </group>
    )
}

export default Gingiva
